import { useLocation } from 'react-router-dom';
import vectionLogo from '../images/vection-logo.png';
import loginBg from '../images/login-bg.svg';

export default function Footer(props) {
  let { pathname } = useLocation();
  return (
    <footer className="logos">
      <div className="footer-logo">
        <img src={vectionLogo} className="img-fluid" style={{ visibility:  pathname !== '/download' ? "visible" : "hidden"}} />
      </div>
      <div className="footer-img">
        <img src={loginBg} className="img-fluid" style={{ visibility:  pathname !== '/learn-more' ? "visible" : "hidden" }} />
      </div>
   
    </footer>
  );
}
