export default function YoutubeEmbed({ embededId }) {
  return (
    <>
      <div className="video-responsive">
        <iframe
          width="420"
          height="315"
          src={`https://www.youtube.com/embed/${embededId}`}
          title="A New Era of Metaverse Presentations: 3DFrame for Webex"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </>
  );
}
