import { _postRequest } from './_apiReq';

export const auth = {
  signin(username, password, cb, errorCb) {
    const formdata = new FormData();
    formdata.append('username', username);
    formdata.append('password', password);

    fetch(process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/login', {
      method: 'POST',
      body: formdata
    })
      .then(response => {
        if (response.ok) return response.json();
        return response.json().then(response => {
          throw response;
        });
      })
      .then(
        result => {
          if (result.status === 'inactive') {
            errorCb({ message: 'Inactive user. Please check your inbox and verify your email address.' });
          } else {
            cb(result);
          }
        },
        error => {
          if (error.status === 403) {
            errorCb({ message: error.error ? error.error : 'No active keys. Please contact Admin.' });
          } else {
            errorCb({ message: error.error ? error.error : 'Invalid credentials! Please try again.' });
          }
        }
      )
      .catch(error => {
        errorCb({ message: 'Invalid credentials' });
      });
  },
  signout(cb) {
    setTimeout(cb, 100);
  }
};
