export function isLatestApp() {
  const app = new window.Webex.Application();
  const versionName = app.about; //   about: "Webex Meetings, Version:42.7.0.126"
  if (versionName) {
    const version = versionName.substring(versionName.indexOf('Version:'), versionName.length).replace('Version:', '').split('.');
    const part1 = version[0] ? parseInt(version[0]) : 0; //
    const part2 = version[0] ? parseInt(version[1]) : 0;
    if (part1 > 42 || (part1 === 42 && part2 >= 7)) {
      console.log("latest app");
      return true;
    }
  }
  return false;
}