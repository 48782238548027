import { _postRequest } from './_apiReq';

export const stripeSubscription = {
  getInfo(cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));

    _postRequest(
      process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/stripe-subscription-info',
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      'Your subscription does not exist'
    );
  }
};
