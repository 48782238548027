import { _memberOnlyRequest, _postRequest } from './_apiReq';

export const scenes = {
  scenesList(cb, errorCb) {
    const formData = new FormData();
    const token = localStorage.getItem('token');
    formData.append('token', token);

    _postRequest(
      process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/scene-list',
      formData,
      result => {
        let xmlData = '';
        var request = new XMLHttpRequest();
        request.open('GET', result.url, true);
        request.overrideMimeType('text/xml');
        request.onload = function () {
          if (request.readyState === request.DONE) {
            if (request.status === 200) {
              xmlData = request.responseText;
              cb({ xmlData: xmlData });
            }
          }
        };
        request.send(null);
      },
      error => errorCb({ message: error }),
      'Could not list the files. Please try again!'
    );
  },

  generateTempKey(sceneName, cb, errorCb) {
    const formData = new FormData();
    const client_id = localStorage.getItem('client_id');
    const token = localStorage.getItem('token');
    formData.append('scenename', sceneName);
    formData.append('clientid', client_id);
    formData.append('token', token);

    _memberOnlyRequest(
      process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/temp-key',
      formData,
      result => cb(result),
      error => errorCb({ message: error.message, showErrorModal: error.showErrorModal }),
      'Something went wrong! Try again.'
    );
  },

  create(sceneName, cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('scene', sceneName);

    _postRequest(
      process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/add-scene',
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      'Could not add the scene.Try again!'
    );
  },
  imagePreview(sceneName, cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('scene_name', sceneName);

    _postRequest(
      process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/scene-preview',
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      'Could not found the scene image.Please try again!'
    );
  },
};
