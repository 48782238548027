import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { scenes } from '../../services/scenes';

export default function CreateScenePage() {
  const [sceneName, setSceneName] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isCreateSceneReqSent, setIsCreateSceneReqSent] = useState(false);
  let navigate = useNavigate();

  useEffect(() => { });

  const handleSubmit = e => {
    e.preventDefault();
    if (!isCreateSceneReqSent) {
      setIsCreateSceneReqSent(true);
      scenes.create(
        sceneName.replace(/\s\s+/g, ' ').replace(' ', '-'),
        result => {
          _resetForm();
          setIsCreateSceneReqSent(false);
          navigate('/dashboard');
        },
        error => {
          _resetForm();
          setErrorMessage('Could not create Scene. Try Again!');
          setTimeout(() => {
            setErrorMessage(null);
          }, 5000);
          setIsCreateSceneReqSent(false);
        }
      );
    }
  };

  const _resetForm = () => {
    document.getElementById('new-scene-form').reset();
    setSceneName(null);
  };

  return (
    <section className="main-content add-scene">
      <div className="row">
        <div className="col-sm-12">
          <h1 className="entry-heading mb-0">Add Scene</h1>
          <div className="spacer-xs-1"></div>
        </div>
      </div>
      <div className="spacer-xs-4"></div>
      <div className="row justify-content-center text-center form-floating-wrapper">
        <div className="create-scene-msg container text-error mb-1">{errorMessage}</div>
        <form onSubmit={e => handleSubmit(e)} method="post" id="new-scene-form">
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control user-floating-input p-3"
              id="floatingInput"
              placeholder="Scene Name"
              required={true}
              onChange={e => {
                setSceneName(e.target.value);
              }}
            />
          </div>
          <div className="col-sm-12">
            <input type="submit" className={'btn btn-primary d-flex justify-content-center w-100 ' + (!sceneName ? 'not-allowed' : '')} value="Submit" />
          </div>
        </form>
      </div>
    </section>
  );
}
