export function isMac() {
  return window.navigator.userAgent.indexOf('Mac') !== -1 ? true : false;
}

export function getOperatingSystem() {
  let operatingSystem = 'Not known';
  if (window.navigator.userAgent.indexOf('Win') !== -1) {
    operatingSystem = 'Windows OS';
  }
  if (window.navigator.userAgent.indexOf('Mac') !== -1) {
    operatingSystem = 'MacOS';
  }
  if (window.navigator.userAgent.indexOf('X11') !== -1) {
    operatingSystem = 'UNIX OS';
  }
  if (window.navigator.userAgent.indexOf('Linux') !== -1) {
    operatingSystem = 'Linux OS';
  }

  return operatingSystem;
}
