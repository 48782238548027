import { useModal } from '../hooks/modal';
const Modal = ({ title, message, handleClose }) => {
  let modal = useModal();
  return (
    <div className={'modal fade modal-mail ' + (modal.isVisible ? 'show' : '')} style={{ display: modal.isVisible ? 'block' : 'none' }}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-error">{title}</h5>
            </div>
          <div className="modal-body">
            <p>{message}</p>
            <div className="spacer-xs-4"></div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={handleClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
