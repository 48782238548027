import { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useRegister } from '../../hooks/register';
import Loader from '../Loader';

export default function ConfirmEmailPage() {
  let register = useRegister();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const validation_token = query.get('validation_token');
  const [isLoading, setIsLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (isLoading) {
      verifyEmail();
    }
  });

  const verifyEmail = () => {
    register.verifyEmail(
      validation_token,
      data => {
        setIsLoading(false);
        setSuccess(true);
      },
      error => {
        setIsLoading(false);
        setSuccess(false);
      }
    );
  };

  return (
    <section className="main-content h-100 ">
      {isLoading ? (
        <Loader />
      ) : success ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <div className="col-12 text-center">
            <h5 className="text-success">Email Verified Successfully.</h5>
            <Link className="text-underline" to={'/login'}>
              Sign In
            </Link>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center h-100">
          <div className="col-12 text-center">
            <h5 className="text-danger">Email Verification Failed.</h5>
            <Link className="text-underline" to={'/signup'}>
              Sign Up
            </Link>
          </div>
        </div>
      )}
    </section>
  );
}
