import { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/auth';
import { stripeSubscription } from '../../services/stripeSubscription';
import moment from 'moment';
import { isLatestApp } from '../../utitls/AppVersion';

const app = new window.Webex.Application();

export default function LicencePage() {
  const [isEnterpriseUser, setIsEnterpriseUser] = useState(false);
  const [isPlanExpired, setIsPlanExpired] = useState(false);
  const [plan, setPlan] = useState(null);
  const auth = useAuth();

  useEffect(() => {
    if (plan) return true;
    stripeSubscription.getInfo(
      response => {
        setPlan({ ...response });
        if (response && response.plan_type === 'Enterprise') {
          setIsEnterpriseUser(true);
          setIsPlanExpired(moment().unix() > response.expiryTime);
        }else {
          setIsPlanExpired(moment().unix() > response.current_period_end);
        }
      },
      error => {
        console.log('error:', error);
      }
    );
  });

  return (
    <section className="main-content licence-page">
      <div className="row">
        <div className="col-sm-12">
          <div className="spacer-xs-1"></div>
          <h2 className="entry-title mb-0">Licence Details</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="plan-detail">
            {plan && (
              <ul className="p-0">
                <li>
                  <span className="text-capitalize">Qty</span>
                  <h3 className="text-capitalize">{plan.qty ? plan.qty : '-'}</h3>
                </li>
                <li>
                  <span className="text-capitalize">Subscription Period</span>
                  <h3 className="text-capitalize">{plan.plan_type ? plan.plan_type : '-'}</h3>
                </li>
                {isEnterpriseUser ? (
                  <>
                    <li>
                      <h3>{plan.creation_date !== null ? moment.unix(plan.creation_date).format('DD MMMM YYYY') : '-'}</h3>
                      <span>Service Effective Date</span>
                    </li>
                    <li>
                      <h3>{plan.expiryTime !== null ? moment.unix(plan.expiryTime).format('DD MMMM YYYY') : '-'}</h3>
                      <span>Service Expiration Date</span>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <h3>{plan.current_period_start !== null ? moment.unix(plan.current_period_start).format('DD MMMM YYYY') : '-'}</h3>
                      <span>Service Effective Date</span>
                    </li>
                    <li>
                      <h3>{plan.next_invoice_date !== null ? moment.unix(plan.next_invoice_date).format('DD MMMM YYYY') : '-'}</h3>
                      <span>Next Invoice Date</span>
                    </li>
                    <li>
                      <h3>{plan.next_invoice_amount ? '$' + plan.next_invoice_amount : 'N/A'}</h3>
                      <span>Next Invoice Amount</span>
                    </li>
                  </>
                )}
              </ul>
            )}

            {isEnterpriseUser || plan && plan.plan_type === 'Flat' && !isPlanExpired ? (
              <></>
            ) : (
              <div className="d-grid gap-2 d-md-flex justify-content-center justify-content-center">
                <span
                  onClick={() => {
                    const url = `${process.env.REACT_APP_FRAMES_URL}/edit-plan`;
                    console.log('url', url);
                    window.open(url, '_blank');
                  }}
                  type="button"
                  className="btn btn-primary me-md-2 text-white btn-custom-edit-plan"
                >
                  Edit Current Plan
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
