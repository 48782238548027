import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import Loader from '../Loader';

export default function LogoutPage() {
  let auth = useAuth();
  let navigate = useNavigate();

  useEffect(() => {
    auth && auth.signout(() => navigate('/login'));
  });

  return (
    <section className="main-content h-100 ">
      <Loader />
    </section>
  );
}
