import { useModal } from '../hooks/modal';
const MobileAlert = ({ title, message, handleClose }) => {
  let modal = useModal();
  return (
    <div className={'modal fade modal-mail ' + (modal.isVisible ? 'show' : '')} style={{ display: modal.isVisible ? 'block' : 'none' }}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-body">
            <p>For best experience use Webex desktop application.</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={handleClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileAlert;
