import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ProvideAuth } from './hooks/auth';
import { ProvideRegister } from './hooks/register';
import LoginPage from './components/pages/LoginPage';
import SignupPage from './components/pages/SignupPage';
import ConfirmEmailPage from './components/pages/ConfirmEmailPage';
import LearnMorePage from './components/pages/LearnMorePage';
import DashboardPage from './components/pages/DashboardPage';
import LogoutPage from './components/pages/LogoutPage';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import { ProvideModal } from './hooks/modal';
import Layout from './components/Layout';
import NoPage from './components/pages/NoPage';
import CreateScenePage from './components/pages/CreateScenePage';
import DownloadPage from './components/pages/DownloadPage';
import ParticipantPage from './components/pages/ParticipantPage';
import InstructionsPage from './components/pages/InstructionsPage';
import './scss/main.scss';
import LicencePage from './components/pages/LicencePage';

export default function App() {
  return (
    <ProvideModal>
      <ProvideRegister>
        <ProvideAuth>
          <BrowserRouter>
            <Routes>
              <Route element={<Layout />}>
                {/* restricted routes like Login, signUp for authorised users */}
                <Route element={<PublicRoute restricted={true} />}>
                  <Route path="/" index element={<LoginPage />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/logout" element={<LogoutPage />} />
                  <Route path="/signup" element={<SignupPage />} />
                  <Route path="/confirm-email" element={<ConfirmEmailPage />} />
                </Route>
                <Route element={<PublicRoute />}>
                  <Route path="/learn-more" element={<LearnMorePage />} />
                  <Route path="/participant/:scene/:mode/:key/:username" element={<ParticipantPage />} />
                  <Route path="/download-app/organizer/:osType" element={<InstructionsPage />} />
                  <Route path="/download-app/participant/:osType" element={<InstructionsPage />} />
                </Route>
                <Route element={<PrivateRoute />}>
                  <Route path="/licence" element={<LicencePage />} />
                  <Route path="/logout" element={<LogoutPage />} />
                  <Route path="/dashboard" element={<DashboardPage />} />
                  <Route path="/create-scene" element={<CreateScenePage />} />
                  <Route path="/download" element={<DownloadPage />} />
                </Route>
                <Route path="*" element={<NoPage />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </ProvideAuth>
      </ProvideRegister>
    </ProvideModal>
  );
}
