import { _postRequest } from './_apiReq';

export const frames = {
  getVersion(cb, errorCb) {
    _postRequest(
      process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/get-version',
      [],
      result => cb(result),
      error => errorCb({ message: error }),
      'Could not list the files.Please try again!'
    );
  }
};
