import image1 from '../../images/1.png';
import image2 from '../../images/2.png';
import image3 from '../../images/3.png';
import YoutubeEmbed from '../YoutubeEmbed';

export default function LearnMorePage() {
  return (
    <section className="main-content ">
      <div className="row">
        <div className="col-sm-12">
          <div className="entry-para">
            <p>Make your presentations immersive by bringing products to life alongside participants in a virtual environment fostering deeper collaboration.</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="entry-para">
          <YoutubeEmbed  embededId="bBuVphyrfuM" title="A New Era of Metaverse Presentations: 3DFrame for Webex" />
          </div>
        </div>
      </div>
      <div className="spacer-xs-3"></div>
      <div className="row">
        <div className="width-xs-40">
          <div className="entry-thumb">
            <img src={image1} className="img-fluid" />
          </div>
        </div>
        <div className="width-xs-60">
          <div className="entry-content">
            <h1>Webex Meetings brought to life in 3D and Virtual Reality (VR).</h1>
            <p>Extend your Webex meeting into a 3D world of your own creation. Explain, learn and collaborate,immersively.</p>
          </div>
        </div>
      </div>
      <div className="spacer-xs-6"></div>
      <div className="row">
        <div className="width-xs-60">
          <div className="entry-content">
            <h1>One Click Launch.</h1>
            <p>Simply click on the App icon in your Webex meeting and search for 3DFrame. Download the powerful App and create next generation 3D presentations.</p>
          </div>
        </div>
        <div className="width-xs-40">
          <div className="entry-thumb">
            <img src={image2} className="img-fluid" />
          </div>
        </div>
      </div>
      
      <div className="row">
        <div className="width-xs-40">
          <div className="entry-thumb">
            <img src={image3} className="img-fluid" />
          </div>
        </div>
        <div className="width-xs-60">
          <div className="entry-content">
            <h1>No-code 3D World Creation.</h1>
            <p>Create your 3D and VR presentations collaboratively or alone. Engage with customers, partners and team members from Webex in your immersive presentation.</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="entry-para">
          <YoutubeEmbed  embededId="videoseries?list=PLP1eKeWNTKK-8ZZ4YGaOTPO92dxyKznes" title="3DFrame Tutorials" />
          </div>
        </div>
      </div>
      
      <div className="spacer-xs-4"></div>
      <div className="row">
        <div className="col-sm-12">
          <a className="btn btn-primary d-flex justify-content-center" href="https://vection-technologies.com">
            Learn All the Features
          </a>
        </div>
      </div>
    </section>
  );
}
