import { _postRequest } from './_apiReq';

export const register = {
  signup(username, password, name, email, country, termConditionFlag, emailFlag, cb, errorCb) {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    formData.append('name', name);
    formData.append('email', email);
    formData.append('country', country);
    formData.append('consent', termConditionFlag ? 'True' : 'False');
    formData.append('notifications', emailFlag ? 'True' : 'False');

    fetch(process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/sign-up', {
      method: 'POST',
      body: formData
    })
      .then(response => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          if (result.success) {
            cb(result);
          } else {
            errorCb(result.message ? result.message : 'Something went wrong! Try again.');
          }
        },
        error => {
          errorCb(error.message ? error.message : 'Something went wrong! Try again.');
        }
      )
      .catch(error => {
        errorCb(error.message ? error.message : 'Something went wrong! Try again.');
      });
  },

  verifyEmail(validation_token, cb, errorCb) {
    const formData = new FormData();
    formData.append('validation_token', validation_token);

    _postRequest(
      process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/confirm-email',
      formData,
      result => cb(result),
      error => errorCb({ message: error })
    );
  },

  getCountryList(cb, errorCb) {
    _postRequest(
      process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/get-countries',
      [],
      result => cb(result),
      error => errorCb({ message: error })
    );
  }
};
