import { Outlet, useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { useModal } from '../hooks/modal';
import {maintenance} from '../services/maintenance'
import { useEffect, useState } from 'react';
import LoaderPage from './pages/LoaderPage';
import MaintenancePage from './pages/MaintenancePage';

export default function Layout() {
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [maintenanceMessage, setMaintenanceMessage] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);

  let modal = useModal();
  let isInnerPage = false;
  const { pathname } = useLocation();
  let showScrollBar = true;
  const aPath = pathname.split('/');
  const innerPages = ['dashboard', 'download', 'download-app', 'create-scene', 'participant'];
  if (aPath.length) {
    isInnerPage = innerPages.includes(aPath[1]);
  }
 
  useEffect(() => {
    maintenance.getMode(
      response => {
        if (response) {
          setDataLoading(false);
          setMaintenanceMode(response.maintenance);
        }
      },
      error => {}
    );
    maintenance.getMessage(
      response => {
        if (response && response.maintenance_message) {
          setMaintenanceMessage(response.maintenance_message);
        }
      },
      error => {}
    );
  });
  
  return (
    <main className={'page-wrapper ' + (isInnerPage ? 'inner' : 'signin-wrapper')}>
      <aside>
        <section className={'sidebar ' + (!modal.isVisible && showScrollBar ? 'overflow-auto' : '')}>
        {maintenanceMessage && <div className="container-fluid px-4 p-2 text-center text-white custom-text-maintenance">{maintenanceMessage}</div>}
          <Header />
          {dataLoading ? <LoaderPage /> : <>{maintenanceMode ? <MaintenancePage /> : <Outlet />}</>}
          <Footer />
        </section>
      </aside>
    </main>
  );
}
