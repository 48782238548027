import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/auth';

const PublicRoute = ({ restricted, ...rest }) => {
  // restricted routes like Login, signUp for authorised users
  let auth = useAuth();
  return auth.isLoggedIn() && restricted ? <Navigate to="/dashboard" /> : <Outlet {...rest} />;
};

export default PublicRoute;
