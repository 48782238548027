import React, { useEffect, useState } from 'react';
import { useModal } from '../../hooks/modal';
import Loader from '../Loader';
import { useParams } from 'react-router-dom';
import { isLatestApp } from '../../utitls/AppVersion';
import DownloadButtons from './common/DownloadButtons';
import MobileAlert from '../MobileAlert';

const app = new window.Webex.Application();

export default function ParticipantPage() {
  const [isAppLoaded, setIsAppLoaded] = useState(false);
  const [appReadyError, setAppReadyError] = useState(false);
  const [appReadyErrorMsg, setAppReadyErrorMsg] = useState(null);
  const [userName, setUserName] = useState(localStorage.getItem('meeting_username') ? localStorage.getItem('meeting_username') : '');
  const [userEmail, setUserEmail] = useState(null);
  const [hasUserDetials, setHasUserDetials] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isFramesLaunched, setIsFramesLaunched] = useState(false);
  const { mode, key } = useParams();
  const modal = useModal();

  useEffect(() => {
    handleAppReady();
  }, []);

  const handleAppReady = () => {
    app
      .onReady()
      .then(() => {
        if (isAppLoaded) {
          return true;
        }
        setIsAppLoaded(true);
        app.context
          .getUser()
          .then(user => {
            if (user.displayName) {
              setUserName(user.displayName);
              setUserEmail(user.email);
              setHasUserDetials(true);
            }
            if (userName && isFramesLaunched === false) {
              setIsFramesLaunched(true);
              let openFramesApp = true;

              _createFramesUrl(userName, user.email, openFramesApp);
            }
          })
          .catch(error => {
            console.log('getUser() promise failed ' + error);
          });
      })
      .catch(error => {
        setAppReadyError(true);
        setAppReadyErrorMsg('onReadyError');
      });
  };

  const _createFramesUrl = (userName, userEmail, openFramesApp) => {
    if (userName) {
      let urlParms = new URLSearchParams({
        mode: mode,
        key: key,
        name: userName,
        email: userEmail
      }).toString();
      if (app.deviceType === 'DESKTOP' && openFramesApp) {
        if (isLatestApp()) {
          app.openUrlInSystemBrowser('frames://app?' + urlParms);
        } else {
          window.location = 'frames://app?' + urlParms;
        }
      } else {
        // modal.show();
        // setShowModal(true);
      }
    }
  };

  return isAppLoaded ? (
    <>
      {/* {showModal && (
        <MobileAlert
          message="For best experience use Webex desktop application."
          handleClose={() => {
            modal.hide();
            setShowModal(false);
          }}
        />
      )} */}
      <section className="main-content download custom-participant-page">
        {/* <div className="row">
          <div className="col-sm-12">
            <h1 className="entry-heading mb-0">This is the last step.</h1>
            <div className="spacer-xs-1"></div>
            <h2 className="entry-title mb-0">Are you ready?</h2>
          </div>
        </div> */}
        {/* <div className="spacer-xs-6"></div> */}
        <div className="row">
          <div className="col-sm-12">
            <div className="click-here">
              {/* <h2 className="entry-heading mb-4">
                <strong>Click the button</strong> to proceed <br />
                with 3DFrame
              </h2> */}

              {!hasUserDetials && (
                <div className="row justify-content-center text-center form-floating-wrapper entry-form custom">
                  <form
                    onSubmit={e => {
                      e.preventDefault();
                      localStorage.setItem('meeting_username', userName);
                    }}
                  >
                    <div className="form-floating mb-3">
                      <i className="icon-user"></i>
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Name"
                        required={true}
                        value={userName}
                        onChange={e => {
                          setUserName(e.target.value);
                          localStorage.setItem('meeting_username', e.target.value);
                        }}
                      />
                      <label htmlFor="floatingInput">Name</label>
                    </div>
                  </form>
                </div>
              )}
              <p>Press here to launch the software</p>
              <button
                className={'btn btn-primary d-flex justify-content-center mt-3 w-100 ' + (!userName ? 'not-allowed' : '')}
                onClick={e => {
                  if (!userName) {
                    return true;
                  }
                  let urlParms = new URLSearchParams({
                    mode: mode,
                    key: key,
                    name: userName,
                    email: userEmail
                  }).toString();

                  if (isLatestApp()) {
                    app.openUrlInSystemBrowser('frames://app?' + urlParms);
                  } else {
                    window.location = 'frames://app?' + urlParms;
                  }
                }}
              >
                Click to launch 3DFrame
              </button>
            </div>
          </div>
        </div>
        <div className="spacer-xs-7"></div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="entry-heading fw-bold mb-4">Don’t have 3DFrame installed?</h2>
          </div>
        </div>
        <div className="row gx-3">
          <DownloadButtons pageFor="participant" />
        </div>
      </section>
    </>
  ) : appReadyError ? (
    <p>{appReadyErrorMsg}</p>
  ) : (
    <Loader />
  );
}
