import DownloadButtons from './common/DownloadButtons';

export default function DownloadPage() {

  return (
    <section className="main-content download">
      <div className="row">
        <div className="col-sm-12">
          <h2 className="entry-heading fw-bold mb-4">Don’t have 3DFrame installed?</h2>
        </div>
      </div>
      <div className="row gx-3">
        <DownloadButtons pageFor="organizer" />
      </div>
      <div className="spacer-xs-8"></div>
    </section>
  );
}
