export default function LoaderPage() {
  return (
    <section className="main-content h-100 ">
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <span>&nbsp;&nbsp;Loading...</span>
      </div>
    </section>
  );
}
