import { _postRequest } from './_apiReq';

export const maintenance = {
  getMode(cb, errorCb) {
    _postRequest(
      process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/get-mode',
      [],
      result => cb(result),
      error => errorCb({ message: error })
    );
  },
  getMessage(cb, errorCb) {
    _postRequest(
      process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/get-message',
      [],
      result => cb(result),
      error => errorCb({ message: error })
    );
  }
};
