import { Link, Navigate, useNavigate } from 'react-router-dom';
import menuSVG from '../images/menu.svg';
import frameLogo from '../images/3dframe-logo.png';
import webexLogo from '../images/webex-logo.png';
import { useAuth } from '../hooks/auth';

export default function Header() {
  let auth = useAuth();
  let navigate = useNavigate();

  return (
    <header>
      <div className="main-header">
        <div className="container">
          <nav className="navbar navbar-light">
            <div className="nav-left">
              <Link className="navbar-brand" to={'/'}>
                <img src={frameLogo} className="img-fuild" />
              </Link>
              <Link className="navbar-brand" to={'/'}>
                <img src={webexLogo} className="img-fuild" />
              </Link>
            </div>
            <div className="nav-right">
              {auth.isLoggedIn() ? (
                <div className="d-lg-flex mobile-menu">
                  <button className="navbar-toggler menu-toggle dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src={menuSVG} />
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <li>
                      <Link className="dropdown-item" to={'/dashboard'}>
                        Dashboard
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to={'/download'}>
                        Download
                      </Link>
                    </li>
                    <li>
                      <span
                        className="dropdown-item"
                        onClick={() => {
                          auth.signout(() => navigate('/'));
                        }}
                      >
                        Log Out
                      </span>
                    </li>
                  </ul>
                </div>
              ) : (
                <></>
              )}
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}
