import underMaintenanceImg from '../../images/exclamation-icon.jpg';

export default function MaintenancePage() {
  return (
    <section className="main-content h-100 ">
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="col-12 text-center">
        <img src={underMaintenanceImg} height="50" className='mb-2' />
        <h5 className="text-primary">Under Maintenance</h5>
        <h5 className="text-primary">We'll Be Back Soon</h5>
        </div>
      </div>
    </section>
  );
}