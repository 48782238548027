import { useEffect, useState } from 'react';
import { frames } from '../../../services/frames';
import windowSVG from '../../../images/windows.svg';
import macSVG from '../../../images/mac.svg';
import { isMac } from '../../../utitls/platform';
import { isLatestApp } from '../../../utitls/AppVersion';

const app = new window.Webex.Application();

export default function DownloadButtons(props) {
  const [urlForMac, setUrlForMac] = useState('');
  const [urlForWindows, setUrlForWindows] = useState('');
  const isMacDevice = isMac();
  const isLatestWebexApp = isLatestApp();

  useEffect(() => {
    if (!urlForMac) {
      frames.getVersion(
        result => {
          setUrlForMac(result.osx_link);
          setUrlForWindows(result.win_link);
        },
        error => {
          console.log('error:', error);
        }
      );
    }
  }, []);

  const downloadApp = osType => {
    var link = document.createElement('a');
    link.target = '_self';
    link.href = osType === 'mac' ? urlForMac : urlForWindows;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadFromBrowser = osType => {
    let url = `${process.env.REACT_APP_WEBEX_URL}/download-app/${props.pageFor}/${osType}`;
    app.openUrlInSystemBrowser(url);
  };

  return (
    <>
      <div className="col-sm-6">
        <div
          className="card custom-clickable"
          onClick={() => {
            if (isMacDevice && isLatestWebexApp) {
              downloadFromBrowser('mac');
            } else {
              downloadApp(isMacDevice ? 'mac' : 'windows');
            }
          }}
        >
          <div className="card-thumb">
            <img src={isMacDevice ? macSVG : windowSVG} className="img-fluid" />
          </div>
          <div className="card-content">
            <p>
              Download for <br /> {isMacDevice ? 'Mac' : 'Windows'}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
