import { _postRequest } from './_apiReq';

export const session = {
  stop(tempkey, cb, errorCb) {
    const formData = new FormData();
    const token = localStorage.getItem('token');
    formData.append('key', tempkey);
    formData.append('token', token);

    _postRequest(
      process.env.REACT_APP_KEY_SERVER_URL + '/license/api/v1/stop-key-session',
      formData,
      result => cb(result),
      error => errorCb({ message: error }),
      'Something went wrong! Try again.'
    );
  }
};
