import { useEffect, useState } from 'react';
import { frames } from '../../services/frames';
import { useLocation, useParams } from 'react-router-dom';

const app = new window.Webex.Application();

export default function InstructionsPage() {
  const [isDownloadStarted, setIsDownloadStarted] = useState(false);
  const [instructions, setInstructions] = useState(false);
  const { osType } = useParams();
  const { pathname } = useLocation();

  useEffect(() => {
    getInstructions();
    if (!isDownloadStarted) {
      frames.getVersion(
        result => {
          if (isDownloadStarted) return true;
          setIsDownloadStarted(true);
          let url = osType === 'mac' ? result.osx_link : result.win_link;
          var link = document.createElement('a');
          link.target = '_self';
          link.href = url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        error => {
          console.log('error:', error);
        }
      );
    }
  }, []);

  const getInstructions = () => {
    let common = ['Download the application', 'Install 3DFrame', 'Open 3DFrame', 'Return to Webex and press LAUNCH button', 'Enjoy your experience'];
    let data = {
      'mac': {
        'participant': ['Download the application', 'Install 3DFrame', 'Open 3DFrame', 'Return to Webex and press LAUNCH button', 'Enjoy your experience'],
        'organizer': ['Download the application', 'Install 3DFrame', 'Open 3DFrame', 'Return to Webex and choose scene', 'Press LAUNCH', 'Enjoy your']
      },
      'windows': {
        'participant': ['Download the application', 'Install 3DFrame', 'Return to Webex and press LAUNCH button', 'Enjoy your experience'],
        'organizer': ['Download the application', 'Install 3DFrame', 'Return to Webex and choose scene', 'Press LAUNCH', 'Enjoy your experience']
      }
    };
    let parts = pathname.replace('/download-app/', '').split('/');
    const pageFor = parts[0];
    setInstructions(data[osType] ? (data[osType][pageFor] ? data[osType][pageFor] : common) : common);
  };

  return (
    <section className="main-content download">
      {/* <div className="row">
        <div className="col-sm-12">
          <h1 className="entry-heading mb-0">This is the last step.</h1>
          <div className="spacer-xs-1"></div>
          <h2 className="entry-title mb-0">Are you ready?</h2>
        </div>
      </div>
      <div className="spacer-xs-6"></div> */}
      <div className="row justify-content-center">
        <div className="entry-info1 getting-started-info">
          <h4 className="text-black">Discover steps </h4>
          <ul className="list-unstyled p-0">
            {instructions &&
              instructions.map((item, index) => {
                return <li className="fw-normal" key={'index-' + index}>{`Step ${index + 1} :  ${item}`}</li>;
              })}
          </ul>
        </div>
      </div>
      <div className="spacer-xs-8"></div>
      <div className="spacer-xs-8"></div>
      <div className="spacer-xs-8"></div>
      <div className="spacer-xs-5"></div>
    </section>
  );
}
