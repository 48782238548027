import { useNavigate } from 'react-router-dom';
import { useModal } from '../hooks/modal';

const ErrorModal = ({ title, message, handleClose }) => {
  let modal = useModal();
  const navigate = useNavigate();
  return (
    <div className={'modal fade modal-mail ' + (modal.isVisible ? 'show' : '')} style={{ display: modal.isVisible ? 'block' : 'none' }}>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-error">Error!</h5>
          </div>
          <div className="modal-body">
            <p>{message}</p>
            Go to { ' '}
            <a
            className='custom-clickable'
              onClick={() => {
                modal.hide();
                navigate('/licence');
              }}
            >
              license page
            </a>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-danger custom-btn-sm" data-dismiss="modal" onClick={handleClose}>
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
