import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/auth';

const PrivateRoute = ({ component: Component, ...rest }) => {
  let auth = useAuth();
  let { pathname } = useLocation();

  if (auth.isLoggedIn()) {
    return <Outlet {...rest} />;
    // if (auth.isSubscriptionActive()) {
    //   return <Outlet {...rest} />;
    // } else {
    //   return pathname !== '/licence' ? <Navigate to="/licence" /> : <Outlet {...rest} />;
    // }
  } else {
    return <Navigate to="/" />;
  }
};
export default PrivateRoute;
