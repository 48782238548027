import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRegister } from '../../hooks/register';
import { useModal } from '../../hooks/modal';
import eye from '../../images/eye.svg';
import eyeSlash from '../../images/eye-slash.svg';

export default function SignupPage() {
  const [user, setUser] = useState({ username: '', password: '', name: '', email: '', country: '' });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [countriesList, setCountriesList] = useState(null);
  const [emailFlag, setEmailFlag] = useState(false);
  const [termConditionFlag, setTermConditionFlag] = useState(false);
  const [country, setCountry] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  let passwordRef = useRef();

  let modal = useModal();
  let navigate = useNavigate();
  let register = useRegister();

  useEffect(() => {
    register.getCountryList(
      response => {
        const { countries } = response;
        setCountriesList(countries);
      },
      error => {
        console.log('error:', error);
      }
    );
  }, []);

  const submit = e => {
    e.preventDefault();
    let passwordExpression = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[()[\]{}|\\`~!@#$%^&*_\-+=;:\'",<>./?])[A-Za-z\d()[\]{}|\\`~!@#$%^&*_\-+=;:\'",<>./?]{8,}$/;
    if (!passwordExpression.test(user.password)) {
      setError(
        "Password must contain at least 8 characters long and at least 1 digit and at least 1 uppercase letter and at least 1 lowercase letter and at least 1 symbol from !()[]{}|`~!@#$%^&*_-+=;:',<>./?"
      );
      return true;
    }
   
    if (!termConditionFlag) {
      setError('Please accept terms and condition');
      return true;
    }
    if (!country) {
      setError('Please accept terms and condition');
      return true;
    }
    setError(null);
    setIsLoading(true);
    register.signup(
      user.username,
      user.password,
      user.name,
      user.email,
      country,
      termConditionFlag,
      emailFlag,
      data => {
        modal.show();
        setShowModal(true);
        setIsLoading(false);
      },
      error => {
        setIsLoading(false);
        setError(error);
      }
    );
  };

  const togglePasswordText = () => {
    if (isPasswordVisible) {
      setIsPasswordVisible(false);
      passwordRef.current.type = 'password';
    } else {
      setIsPasswordVisible(true);
      passwordRef.current.type = 'text';
    }
  };

  return (
    <>
      <section className="main-content cutsom-signin-page">
        <div className="row">
          <div className="col-sm-12">
            <h1 className="entry-title mb-3">Signup for your 14 days free trial</h1>
            <h2 className="entry-heading">No credit card required. </h2>
            <div className="text-danger mb-3">{error}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="entry-form">
              <form onSubmit={e => submit(e)} className="signup-form">
                <div className="form-floating mb-3">
                  <i className="icon-user"></i>
                  <input type="text" className="form-control" placeholder="Name" required={true} value={user.name} onChange={e => setUser({ ...user, name: e.target.value })} />
                  <label htmlFor="Name">Name</label>
                </div>
                <div className="form-floating mb-3">
                  <i className="icon-email"></i>
                  <input type="email" className="form-control" placeholder="Email" required={true} value={user.email} onChange={e => setUser({ ...user, email: e.target.value })} />
                  <label htmlFor="Email">Work Email</label>
                </div>
                <div className="form-floating mb-3">
                  <i className="icon-lock"></i>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="username"
                    required={true}
                    value={user.username}
                    onChange={e => setUser({ ...user, username: e.target.value })}
                  />
                  <label htmlFor="bname">Username</label>
                </div>
                <div className="form-floating mb-3">
                  <i className="icon-key"></i>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    required={true}
                    value={user.password}
                    onChange={e => setUser({ ...user, password: e.target.value })}
                    pattern="(?=.*\d)(?=.*[!()[\]{}|\\`~!@#$%^&*_\-+=;:',<>./?])(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    title="Password must contain at least 8 characters long and at least 1 digit and at least 1 uppercase letter and at least 1 lowercase letter and at least 1 symbol from !()[]{}|\`~!@#$%^&*_\-+=;:',<>./?"
                    ref={passwordRef}
                  />
                  <img
                    className="img-fluid"
                    src={isPasswordVisible ? eye : eyeSlash}
                    alt="icon-seen"
                    onClick={() => {
                      togglePasswordText();
                    }}
                  />
                  <label htmlFor="floatingInput">Password</label>
                </div>
                <div className="form-floating">
                  <i className="icon-glob"></i>
                  <select className="form-select" aria-label="Country" onChange={e => setCountry(e.target.value)} placeholder="Select Country">
                    <option value=""></option>
                    {countriesList &&
                      countriesList.map((item, key) => {
                        return (
                          <option value={item.id} key={'country-' + item.country_name}>
                            {item.country_name}
                          </option>
                        );
                      })}
                  </select>
                  <label htmlFor="floatingSelect">Country</label>
                </div>
                <div className="form-check mb-3">
                  <input className="form-check-input" type="checkbox" name="terms" id="terms" onChange={e => setTermConditionFlag(e.target.checked)} />
                  <label className="form-check-label mt-2" htmlFor="gridCheck">
                    I agree to Vection &nbsp;
                    <a href="#" className="link-primary text-decoration-underline ">
                      Terms
                    </a>
                    &nbsp; and &nbsp;
                    <a href="#" className="link-primary text-decoration-underline">
                      Privacy Policy
                    </a>
                    .
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" name="emailUpdate" id="terms" onChange={e => setEmailFlag(e.target.checked)} />
                  <label className="form-check-label mt-2" htmlFor="gridCheck">
                    I agree to receive Vection news and updates.
                  </label>
                </div>
                <div className="spacer-xs-6"></div>
                <button className={'btn btn-primary d-flex justify-content-center w-100 login-btn ' + (isLoading ? 'isDisabled' : '')} type="submit">
                  {isLoading ? (
                    <>
                      <div className="spinner-border spinner-border-sm" role="status"></div>
                      <span className="sr-only mt-1">&nbsp; Get Started Now...</span>
                    </>
                  ) : (
                    <span className="sr-only mt-1">&nbsp; Get Started Now</span>
                  )}
                </button>

                <div className="spacer-xs-2"></div>
                <span
                  className="btn btn-primary-light d-flex justify-content-center"
                  onClick={() => {
                    const url = `${process.env.REACT_APP_WEBEX_URL}/learn-more`;
                    window.open(url, '_blank');
                  }}
                >
                  Learn More
                </span>
              </form>
            </div>
          </div>
        </div>
      </section>
      {showModal && (
        <div className={'modal fade modal-mail ' + (modal.isVisible ? 'show' : '')} style={{ display: modal.isVisible ? 'block' : 'none' }}>
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setShowModal(false);
                    modal.hide();
                    navigate('/login');
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <h4>Almost there!</h4>
                <p>Check your mailbox to confirm your email address and download 3DFrame to start your 3D and Virtual Reality experience, right inside of Webex.</p>
                <div className="spacer-xs-4"></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
