import { Link } from 'react-router-dom';

export default function NoPage() {
  return (
    <section className="main-content h-100 ">
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="row ">
          <h1 className="text-center">No Page Found!</h1>
          <br />
          <Link className={'d-flex justify-content-center'} to={'/'}>
            Home
          </Link>
        </div>
      </div>
    </section>
  );
}
