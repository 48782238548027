import { useRef, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import eye from '../../images/eye.svg';
import eyeSlash from '../../images/eye-slash.svg';

export default function LoginPage() {
  let navigate = useNavigate();
  let auth = useAuth();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({ username: localStorage.getItem('auth_username') ? localStorage.getItem('auth_username') : '', password: '' });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  let passwordRef = useRef();

  const submit = e => {
    e.preventDefault();
    setIsLoading(true);
    localStorage.setItem('auth_username', user.username);
    auth.signin(
      user.username,
      user.password,
      data => {
        navigate('/dashboard');
        // if (data.stripe_status === 'active') {
        //   navigate('/dashboard');
        // } else {
        //   navigate('/licence');
        // }
      },
      error => {
        setIsLoading(false);
        setError(error);
      }
    );
  };

  const togglePasswordText = () => {
    if (isPasswordVisible) {
      setIsPasswordVisible(false);
      passwordRef.current.type = 'password';
    } else {
      setIsPasswordVisible(true);
      passwordRef.current.type = 'text';
    }
  };

  return (
    <section className="main-content custom-login-page">
      <div className="row">
        <div className="col-sm-12">
          <h1 className="entry-title custom-entry-title">Bring your Webex meetings to life with 3DFrame.</h1>
          <h2 className="entry-heading">Sign In</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="entry-form">
            <form onSubmit={e => submit(e)}>
              <div className="form-floating mb-3">
                <i className="icon-user"></i>
                <input
                  type="text"
                  className="form-control"
                  id="floatingInputUsername"
                  placeholder="Username"
                  required={true}
                  value={user.username}
                  onChange={e => setUser({ ...user, username: e.target.value })}
                />
                <label htmlFor="floatingInputUsername">Account</label>
              </div>
              <div className="form-floating mb-3">
                <i className="icon-key"></i>
                <input
                  type="password"
                  className="form-control"
                  id="floatingInputPassword"
                  placeholder="password"
                  required={true}
                  value={user.password}
                  onChange={e => setUser({ ...user, password: e.target.value })}
                  ref={passwordRef}
                />
                <img
                  className="img-fluid"
                  src={isPasswordVisible ? eye : eyeSlash}
                  alt="icon-seen"
                  onClick={() => {
                    togglePasswordText();
                  }}
                />
                <label htmlFor="floatingInputPassword">Password</label>
              </div>
              <button className={'btn btn-primary d-flex justify-content-center w-100 login-btn  ' + (isLoading ? 'isDisabled' : '')} type="submit">
                {isLoading ? <span className="spinner-border spinner-border-sm " role="status" aria-hidden="true"></span> : null}
                &nbsp;
                <span className="mt-1"> Login </span>
              </button>
              <div className="spacer-xs-2"></div>
              {error ? <div className="text-error text-center mb-1 text-danger ">Error: {error.message}</div> : null}
              <p className="text-center">
                Don’t have an account? &nbsp;
                <Link to="/signup" className="link">
                  Sign Up
                </Link>
              </p>
              <div className="spacer-sm-3"></div>
              <span
                className="btn btn-primary-light d-flex justify-content-center"
                onClick={() => {
                  const url = `${process.env.REACT_APP_WEBEX_URL}/learn-more`;
                  window.open(url, '_blank');
                }}
              >
                Learn More
              </span>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
